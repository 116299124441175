.wrapper {
  width: 100%;
  max-width: 600px;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 100/11;
  }
}

.navLink {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.socialLink {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  &:hover {
    background-color: #fff;
    svg {
      fill: #000;
    }
  }
}

.containerWidget {
  border: 1px solid white;
  height: 3.6875rem;
  width: 13.4375rem;
  background-color: black;
  cursor: pointer;
  margin-bottom: 2.25rem;

  img {
    height: 20px!important;
    width: 20px!important;
  }
}

.textWidget {
  font-size: 0.8125rem;
  letter-spacing: 0.033125rem;
  color: #ffffff;
}