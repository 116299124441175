.search {
  display: flex;
  height: 2.065rem;
  //width: 14.85rem;
  font-family: var(--font-playfair);

  input {
    padding: 0 0.3rem 0 0.75rem;
    border: 1px solid black;
    font-size: 0.875rem;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    width: 13.75rem;
    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: #000000;
  }

  button {
    align-items: center;
    padding: 0.1rem 1rem;
  }

  .searchButton {
    @extend button;
    border: 1px solid black;
    background: #000 url('/intstatic/icons/search-white.svg') center no-repeat;
  }

  .clearButton {
    @extend button;
    position: absolute;
    right: 2.1rem;
    height: 2.065rem;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
    background: #fff url('/intstatic/icons/clear-search.svg') center no-repeat;
  }
}

.suggestions {
  list-style-type: none;
  position: absolute;
  z-index: 15;
  background-color: #ffffff;
  width: 100%;
  padding: 0.625rem 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;

  li {
    padding: 0 0.625rem;

    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
    }
  }
}
