.initialButton {
  position: fixed;
  bottom: 2.187rem;
  right: 1.375rem;
  overflow: hidden;
  border: none;
  cursor: pointer;
  @media (max-width: 767px) {
    bottom: 2rem;
  }
}

.block {
  -webkit-animation: fadeIn 0.3s linear forwards;
  animation: fadeIn 0.3s linear forwards;
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      z-index: 0;
    }
    100% {
      opacity: 1;
      z-index: 110;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      z-index: 0;
    }
    100% {
      opacity: 1;
      z-index: 110;
    }
  }
}
.nonBlock {
  -webkit-animation: fadeOut 0.3s linear forwards;
  animation: fadeOut 0.3s linear forwards;
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
      z-index: 110;
    }
    99% {
      opacity: 0;
      z-index: 0;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      z-index: 110;
    }
    99% {
      opacity: 0;
      z-index: 0;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
}
