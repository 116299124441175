.dim {
  z-index: 99;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 40%);
  transition: all 0.5s ease-in-out;
  @media (max-width: 991px) {
    overflow-y: scroll;
  }
}

.container {
  background-color: white;
  padding: 1.5rem !important;
  max-width: 1100px;
  width: 100%;
  overflow-y: auto;
  border-radius: 1rem 1rem 0 0;
  height: calc(100% - 60px);

  @media (min-width: 767px) {
    max-height: 83vh;
    margin-top: 4rem;
    position: relative;
    border-radius: 0;
  }
}
.containervoucher {
  background-color: white;
  padding: 1.5rem !important;
  max-width: 500px;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.containersize {
  background-color: white;
  padding: 1.5rem !important;
  width: 100%;
  position: relative;
  overflow-y: auto;
  margin-top: 4rem;

  @media (max-width: 767px) {
    margin-top: 0;
    position: unset;
  }
}
.closeButtonSizes {
  position: absolute;
  @media (max-width: 767px) {
    right: 4rem;
  }
}

.sizes {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: 0.875rem;
}

.prices {
  color: #9b9b9b;
}

.groupColors {
  border-bottom: 1px solid black;
  padding-bottom: 1.25rem;
}

.colorActive {
  position: relative;
}
.colorActive:after {
  content: '';
  position: absolute;
  width: 0.812rem;
  height: 1px;
  bottom: -5px;
  right: 32%;
  background: black;
}
.name {
  text-transform: none;
  display: block;
}
.vat {
  margin-left: 1rem;
  font-size: 0.625rem;
}

.best-price {
  display: block;
  font-size: 0.813rem;
  font-weight: 500;
  color: #b5b4b4;
}

.btnUnavailable {
  background: #757575;
  pointer-events: none;
}
.textUnavailable {
  font-size: 1rem;
  font-style: italic;
  font-family: var(--font-playfair);
  padding: 0.625rem 0;
  display: block;
}

.linkBrands {
  font-family: var(--font-alegreya);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 20px;
  text-transform: uppercase;

  a {
    text-decoration-color: #000000;
    color: #000000;
  }
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
}
.accordion {
  border-bottom: 1px solid black;
  @media (max-width: 768px) {
    border-bottom: 1px solid #eeeeee;
  }
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  padding: 1rem 0;

  img {
    transition: transform 0.15s ease-in;
  }
}

.accordion-body {
  overflow-y: auto;
  transition: all 0.15s ease-in;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 2px;
  }
}
.discountLabel {
  position: absolute;
  top: 0.5rem;
  left: 0;
  background-color: black;
  color: white;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 1;
  @media (min-width: 767px) {
    left: 1.5rem !important;
  }
}

.shipping {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.12rem;
}

.info {
  text-transform: uppercase;
  font-size: 0.75rem;
  @media (min-width: 576px) {
    font-size: 0.875rem;
  }
}

//Coupon Label PDP
.couponContainer {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.coupon {
  font-size: 0.625rem;
  font-weight: 500;
  text-align: start;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  font-family: var(--font-alegreya);
  color: #ffffff;
  position: relative; /* Ensures the ::before triangle is positioned relative to this */
  //width: 220px;  /* Fixed width */
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  display: flex;
  white-space: nowrap;  /* Prevent text wrapping */
  /* Remove overflow hidden so the triangle is not clipped */
  overflow: visible; /* Allow the triangle to be visible outside of the coupon boundaries */

  //@media(max-width: 768px) {
  //  width: 150px;
  //}
}

.coupon::before {
  @media(min-width: 768px) {
    content: '';
    position: absolute;
    top: -5px; /* Places the triangle just above the coupon */
    left: 20px; /* Adjust this value to position the triangle horizontally */
    width: 0;
    height: 0;
    border-left: 6px solid transparent; /* Left side of the triangle */
    border-right: 6px solid transparent; /* Right side of the triangle */
    /* The bottom part creates the triangle */
    z-index: 2; /* Ensure this is above other elements */
  }
}

//coupon label width based on if the overlay is opened

.regularLabel {
  @media(min-width: 768px) {
    width: 150px;
  }
}

.expandedLabel {
  @media(min-width: 768px) {
    width: 220px;
  }
}
//background if coupon is applied on full price
.regular{
  background-color: #eb4e4e;
}

.regular::before {
  border-bottom: 6px solid #eb4e4e;
}

//background if coupon is applied on discounted price
.extra {
  background-color: #7B96E1;
}

.extra:before {
  border-bottom: 6px solid #7B96E1;
}

.blackFriday {
  background-color: #000000;
}

.blackFriday:before {
  border-bottom: 6px solid #000000;
}

.linkSizes {
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.description {
  a {
    text-decoration-color: #000000;
    color: #000000;
  }
}

.oosDisclaimer {
  font-weight: 700;
  font-size: 0.937rem;

  @media (min-width: 576px) {
    font-size: 1.25rem;
  }
}
