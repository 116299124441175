
.itemImg {
  object-fit: contain;
  object-position: bottom;
  // width: 100%;
  height: auto;

  &:hover {
    opacity: 0.7;
  }
}

.imgUnavailable {
  opacity: 0.4;
}

.labelUnavailable {
  position: absolute;
  top: 35%;
  opacity: 0.6;
  left: 35%;
  font-family: var(--font-playfair);
  font-style: italic;
  letter-spacing: 0.01rem;
  font-size: 0.875rem;
  font-weight: normal;

  @media (min-width: 767px) {
    top: 40%;
    left: 40%;
  }
}

.discountLabel {
  position: absolute;
  top: 1rem;
  left: 0;
  background-color: black;
  color: white;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 1;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;


}

.couponLabel {
  position: absolute;
  bottom: 1.125rem;
  left: 0;
  //background-color: #EB4E4E;
  font-size: 0.688rem;
  font-weight: 700;
  font-family: var(--font-alegreya);
  padding: 0.25rem 0.4rem;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  color: #ffffff;
  z-index: 1;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

//Coupon CountDown -> Black Friday
.countDownLabel {
  position: absolute;
  bottom: 2.55rem;
  background-color: #ffffff;
  left: 0;
  font-family: var(--font-alegreya);
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.25rem 0.4rem;
  z-index: 1;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.countDownPDP {
  margin-left: 0.625rem;
  margin-top: 0.5rem;
  font-family: var(--font-alegreya);
  font-size: 0.75rem;
  font-weight: 700;
  display: inline-flex;
  @media(min-width: 768px) {
    margin-left: 1rem;
    margin-top: 0;
  }
}

.wishlistHeart {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  z-index: 1;
}

.originalPrice {
  font-size: 0.813rem;
  font-weight: 500;
  color: #9b9b9b;

  @media (max-width: 576px) {
    font-size: 0.75rem;
  }
}

.price {
  font-size: 1.125rem;
  font-weight: 500;
  @media (max-width: 576px){
    font-size: 0.875rem;
  }
}

.showText {
  font-family: var(--font-alegreya);
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: underline;
}

.brandSeo {
  @media (max-width: 768px) {
    color: #979797;
  }
}



//List item - quick view, coupon overlay
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}

.overlayContent {
  color: #ffffff;
  position: relative;
  max-width: 100%;
  text-align: center;
  @media (max-width: 768px), (min-width: 992px) and (max-width: 1300px) {
    padding-top: 1.125rem;
  }
}

.closeBtn {
  position: absolute;
  top: 0.313rem;
  right: 0.625rem;
  cursor: pointer;
  padding: 0.1rem 0.1rem 0.75rem 0.75rem;
}

.title {
  font-size: 1rem;
  font-family: var(--font-alegreya);
  font-weight: 700;
  @media (max-width: 576px), (min-width: 992px) and (max-width: 1350px) {
    font-size: 0.875rem;
  }
}

.sizesWrapper {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.size {
  display: inline-block;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 0.875rem;
    margin: 0.25rem 0.3rem;
  }
}

.moreSizes {
  color: #ffffff;
  cursor: pointer;
  font-size: 0.688rem;
}

.details {
  margin-bottom: 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 768px), (min-width: 992px) and (max-width: 1300px) {
    font-size: 0.875rem;
  }
}

.shopBtn {
  background-color: #ffffff;
  color: #000000;
  width: 12.5rem;
  margin: 0.625rem auto 0;
  padding: 0.625rem 0.75rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.875rem;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 576px), (min-width: 1100px) and (max-width: 1350px) {
    width: 9.375rem;
    padding: 0.5rem;
    font-size: 0.7rem;
  }

  @media (min-width: 992px) and (max-width: 1100px) {
    width: 98%;
    font-size: 0.75rem;
  }
}

.shippingCost {
  padding-bottom: 2rem;
}

.quickViewCoupon {
  display: flex;
  justify-content: center;
}


//Coupon + colours variations -> space adjustment on quickView
.couponApplied {
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media(max-width: 400px), (min-width: 992px) and (max-width: 1100px) {
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @media(min-width: 1100px) and (max-width: 1350px) {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
}
.couponColorsApplied {
  margin-top: 1rem;
  padding-bottom: 2rem;
  @media(max-width: 400px), (min-width: 992px) and (max-width: 1100px) {
    margin-top: 0.25rem;
    padding-bottom: 0.5rem;
  }
  @media(min-width: 1100px) and (max-width: 1350px) {
    margin-top: 0.5rem;
    padding-bottom: 1rem;
  }
}

.colorsApplied {
  margin-top: 2.5rem;
  padding-bottom: 1.75rem;
  @media(max-width: 400px),(min-width: 992px) and (max-width: 1100px) {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
}
.noColorsOrCouponApplied {
  margin-top: 3.5rem;
  padding-bottom: 2rem;
  @media(max-width: 400px), (min-width: 992px) and (max-width: 1100px) {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }

}


//Coupon PDP overlay
.pdpOverlay {
  position: absolute; /* Positioned relative to the .couponContainer */
  top: 100%; /* Directly below the label */
  left: 0;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 370px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  @media (max-width: 768px) {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.overlayMobileBackground {
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 900;
  }
}



//List item multiple images - DESKTOP
.imageContainer {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  &:hover .leftArrow,
  &:hover .rightArrow {
    display: block;
  }
}

.hoveredImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dotContainer {
  position: absolute;
  bottom: 0.15rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.25rem;
  padding: 0.2rem 0.5rem; /* Add padding around the dots */
  background-color: rgba(255, 255, 255, 0.4); /* White background with 80% opacity */
  border-radius: 1.5rem; /* Make the background rounded */
}

.dot {
  width: 0.35rem;
  height: 0.35rem;
  background-color: #979797;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.activeDot {
  background-color: #494949;
}

.leftArrow,
.rightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0.125rem 0.5rem;
  cursor: pointer;
  z-index: 10;
  display: none;
}

.leftArrow {
  left: 0;
}

.rightArrow {
  right: 0;
}

//List item multiple images - MOBILE
.sliderContainer {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide overflow so images don't appear outside the container */
}

.imageSlider {
  display: flex; /* Use flexbox to align images in a row */
  transition: transform 0.5s ease-in-out; /* Smooth transition for sliding effect */
}

.sliderImage {
  min-width: 100%; /* Each image takes up 100% of the container width */
  height: auto; /* Adjust height based on the image */
}


// Coupon overlay - LIST ITEMS
.couponSubtitle {
  font-size: 0.75rem;
  font-family: var(--font-alegreya);
  margin-bottom: 2rem;
  @media(max-width: 400px) {
    margin-bottom: 1.5rem;
  }
  @media(min-width: 992px) and (max-width: 1250px) {
    margin-bottom: 1rem;
  }
  @media(max-width: 1350px) {
    font-size: 0.625rem;
  }
}

.copyCode {
  position: absolute;
  top:0;
  right: 0.5rem;
}

.couponBox {
  border: 1px solid #ffffff;
  justify-content: center;
  width: fit-content;
  margin: 0 auto 0.5rem auto;
}

.couponInfo {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  @media(min-width: 1351px) and (max-width: 1400px) {
    padding: 0 0.5rem;
  }
}

.couponTitle {
  font-size: 0.625rem;
  margin-bottom: 2rem;
  @media(max-width: 576px) {
    margin-bottom: 1.25rem;
  }
  @media(min-width: 992px) and (max-width: 1250px) {
    margin-bottom: 0.5rem;
  }
}


//ColorBox on quick View
.colorWrapper {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}


.colorCounter {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 300;
}

.colorLabel {
  position: absolute;
  display: flex;
  bottom: 1.125rem;
  right: 0;
  background-color: #ffffff;
  padding-left: 0.125rem;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 1;
  align-items: center;
  border: 0.3px solid #7E7E7E;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}