.horizontalScroll {
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    height: 0;
  }

  a {
    text-decoration: none;
    color: #1c1c1c;
    font-weight: 500;
    font-size: 0.875rem;
    background: #f3f3f3;
    border-radius: 2px;
    padding: 0.3rem 0.8rem;
    &:hover {
      background: #1c1c1c;
      color: #f3f3f3;
    }
  }

  .selected {
    background: #1c1c1c;
    color: #f3f3f3;
  }

  button {
    position: absolute;
    z-index: 2;
    width: 50px;
    height: 33px;
    border: none;
  }

}

.btnLeft {
  left: 0;
  background: linear-gradient(to right, white, white, transparent);
}

.btnRight {
  right: 0;
  background: linear-gradient(to left, white, white, transparent);
}
