.paginationMain {
  display: flex;
  justify-content: center;
  list-style: none;
  @media (max-width: 991px) {
    padding: 0;
  }
}

.paginationItem {
  min-height: 1.5rem;
  margin-left: 0.3125rem;
  padding: 0.1875rem 0.625rem 0.0625rem;
  border-radius: 0.1875rem;
  font-size: 0.875rem;
  font-family: var(--font-alegreya);
  color: #999;

  &:hover {
    background-color: #e6e5e5;
    cursor: pointer;
  }
}

.active {
  background: black;
  color: #fff;
}

.disabledItem {
  &:hover {
    background-color: #e6e5e5;
    cursor: pointer;
  }
}
