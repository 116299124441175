.menu-list-enter-submenu {
  transform: translateX(100%);
}

.menu-list-enter-topmenu {
  transform: translateX(-100%);
}

.menu-list-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}

.menu-list-exit-submenu {
  opacity: 1;
  transform: translateX(0);
}

.menu-list-exit-topmenu {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.menu-list-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.menulist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-container {
  overflow: hidden;
}

.menulist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menulist {
  display: flex;
  flex-direction: column;
}

.menuItemActive {
  filter: invert(1);
}

.menuitem {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.25rem;
}

.exit {
  transform: translateX(0%);
}

.enterRight {
  transform: translateX(100%);
}

.enterLeft {
  transform: translateX(-100%);
}

.enterActive {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}

.exit {
  transform: translateX(0%);
}

.exitActiveRight {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.exitActiveLeft {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}
