.onlineshopsLetter {
  width: 100%;
  display: table;
  font-size: 1.875rem;

  &::after {
    content: '';
    display: table-cell;
    border-bottom: 2px solid black;
    width: 92%;
    transform: translate(-0.5rem, -1rem);
  }

  @media (min-width: 767px) {
    font-size: 2.1875rem;
    &::after {
      width: 100%;
      transform: translate(0.5rem, -1rem);
    }
  }
}

.onlineshopsSgLinks {
  color: #343434;
  font-size: 1rem;

  &:hover {
    color: black;
    font-weight: bold;
  }

  @media (min-width: 767px) {
    font-size: 1.062rem;
  }
}


.headline {
  background-color: #f6f5f1;
  padding: 0.312rem 0;
  text-align: center;
  font-style: italic;
  font-family: var(--font-playfair);
  font-weight: normal;
  line-height: 1.15;
}

.linkAction {
  position: absolute;
  inset: 0;
}