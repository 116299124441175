.letterTitle {
  font-size: 1.875rem;
  line-height: 1.2rem;

  @media (min-width: 767px) {
    font-size: 2.1875rem;
    line-height: 1.5rem;
  }
}

.line {
  height: 2px;
  background-color: #000;
  width: 100%;
}

.brandsContainer {
  display: block;
  column-count: 1;
}

.brandsContainerLetter {
  display: block;
  column-count: 2;
  @media (min-width: 1265px) {
    column-count: 4;
  }
}
.brandName {
  color: #343434;
  font-family: var(--font-alegreya);

  &:hover {
    color: black;
    font-weight: bold;
  }
}

.content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.content::-webkit-scrollbar {
  display: none;
}

.lettersContainer {

  height: 4.6rem;
}

.letter {
  font-family: ITCBlair-Light;
  font-size: 1.2rem;
  color: #cecece;
  cursor: pointer;

  &:hover {
    color: black;
    font-weight: bold;
  }
}
.activeLetter {
  color: black;
  font-weight: bold;
}
.allBrands {
  font-size: 0.8rem;
  font-style: italic;
  color: #343434;
  text-decoration: none;
  font-family: var(--font-playfair);

  &:hover {
    text-decoration: underline;
  }
}
