//languageswitcher
.arrowdown {
  transform: rotate(180deg);
}

.arrowup {
  @extend .arrowdown;
  transform: rotate(0deg);
}

.open {
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
}

.closed {
  border: 1px solid transparent;
}

//profilelinks
//hover underline
.profilelinks {
  text-decoration: none;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
//Search Bar Mobile
@mixin base-style {
  display: flex;
  position: relative;
  justify-content: space-between;
  input {
    border: 0;
    margin: 0;
    outline: 0;
    font-family: var(--font-alegreya);
    font-size: 0.937rem;
    box-sizing: border-box;

    // https://stackoverflow.com/q/6370690
    @media (max-width: 768px) {
      // The browser will zoom if the font-size is less than 16px and
      // the default font-size for form elements is 11px (at least in Chrome and Safari).
      // https://stackoverflow.com/a/6394497
      font-size: 20px !important;
    }

    &::placeholder {
      color: white;
    }

    &:focus::-webkit-input-placeholder {
      color: #898989 !important;
    }

    &:focus::-moz-placeholder {
      color: #898989 !important;
    }

    &::-ms-clear {
      display: none;
    }
  }
}

.default-theme-no-focus {
  @include base-style;
  border-bottom: 1px solid black;
  border-top: 0 solid #ffffff;
  border-left: 0 solid #ffffff;
  width: 105%;
  input {
    width: 100%;
  }
  img {
    padding-top: 0.75rem;
    height: 1.812rem;
  }
}
