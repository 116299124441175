.tooltip {
  position: absolute;
  opacity: 1;
  background-color: #ddd;
  padding: 1.3rem 1.2rem 1.2rem;
  z-index: 100;
  font-family: 'Alegreya Sans', sans-serif;
  line-height: 1.2rem;
  font-size: 0.875rem !important;
  font-weight: normal !important;
  color: #333;
  transition: opacity 0.1s linear;
  width: 260px;
  left: 0;
  margin-top: 25px;

  span {
    display: inline;
  }
  a {
    color: black;
    font-weight: bold;
  }

  & .nub {
    display: block;
    left: 0.5rem;
    position: absolute;
    width: 0;
    height: 0;
    border: solid 8px;
    border-color: transparent transparent #ddd transparent;
    top: -0.9rem;
  }

  .exit {
    position: absolute;
    top: 0.2rem;
    font-size: 1.4rem;
    color: #777;
    cursor: pointer;
    right: 0.625rem;
  }

  .exit:hover {
    color: #eee;
  }
}
