/******** GALLERY *********/
.gallery {
  position: relative;
  height: 30rem;

}

.gallerySidebar {
  width: 143px;
  overflow-y: hidden;
}

.galleryMain {
  position: relative;
  text-align: center;
}

.discountLabel {
  background-color: black;
  color: white;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 1;
  position: absolute;
}

.wishlistHeart {
  position: absolute;
  cursor: pointer;
  top:0;
  right: 1.5rem;
  z-index: 1;
  @media(max-width: 992px) {
    right: 1rem;
  }
}

.recentViews {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 170px;
  background-color: #ffffff;
  color: #000000;
  font-size: 10px;
  font-family: var(--font-inter);
  padding: 0.25rem 0.5rem;
  box-shadow: 0 4px 4px 0 #00000040;
  display: flex;
  align-items: center; /* Aligns the dot and text in a row */
}

.pulsingDot {
  height: 5px;
  width: 5px;
  background-color: #FD544A; /* Red color */
  border-radius: 50%;
  margin-right: 8px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.galleryImages:hover {
  cursor: pointer;
}

.imageMain {
  width: auto;
  height: 35rem;
}

.galleryThumbnail {
  width: 100%;
  height: auto;
  padding: 0 0.25rem 1rem 0;
  display: block;
}

.galleryThumbnail:last-child {
  padding: 0 0.25rem 0 0;
  margin-bottom: 0;
}

.arrow {
  display: flex;
  align-content: center;
  justify-content: center;
}
.soldOut {
  opacity: 0.4;
}

/****** CAROUSEL *********/
.galleryCarousel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
}

.carouselWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.carouselDim {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //z-index: 10000000;
  background: #fff;
  opacity: 0.7;
  &:hover {
    cursor: zoom-in;
  }
}

.arrowContainer {
  position: absolute;
  z-index: 10000002;
  width: 10rem;
  height: 19rem;
  align-items: center;
  justify-content: center;
}

.arrowWrapper {
  width: 10rem;
  height: 19rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  position: absolute;
  z-index: 10000002;
  right: 3rem;
  top: 2rem;
}

.slideImage{
  background: transparent;
  height: 100%;
  width: auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &:hover {
    cursor: zoom-in;
  }
}


/* ZOOM IMAGE */
.zoomImage {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: absolute;
  transition: all 0.1s;
  &:hover {
    cursor: zoom-in;
  }
}

.absoluteFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hidden {
  visibility: hidden;
  display: none;
}