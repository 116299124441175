.listing {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    text-decoration: none;
    color: #343434;
  }

  img:hover {
    opacity: 0.7;
  }
}

.headline {
  font-family: var(--font-playfair);
  font-style: italic;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  height: 4rem;
  @media (max-width: 768px) {
    height: 2.5rem;
    font-size: 1rem;
  }
}

.editor {
  margin-top: 0.5rem;
  font-family: var(--font-playfair);
  font-style: italic;
  font-size: 1rem;
  color: #797979;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}

.title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
}

.width {
  max-width: 12.5rem;
  padding: 0.5rem 0;
}

.txt {
  font-family: var(--font-alegreya);
  font-size: 0.9rem;
  margin: 0.9rem 0 1.5rem;
  letter-spacing: 0.07rem;
}

.editorImg {
  width: 2.75rem;
  height: 2.75rem;
  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
}