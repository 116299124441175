.content-title {
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.08rem;
  margin-bottom: 0.5rem;
  &:hover, &:active {
    text-decoration: none;
    color: #000000;
  }
}

.content-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  a {
    font-weight: 500;
    color: #1c1c1c;
    letter-spacing: 0.4px;
    font-size: 0.875rem;
  }
}
