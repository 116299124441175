.heroButtons {
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.heroButtonsMobile {
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.heroButton {
  margin-bottom: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  background-color: #ffffffb3;
  font-weight: bold;
  padding: 0.5rem 1.75rem;

  @media (max-width: 768px) {
    color: white;
    background-color: black;
  }
}

.heroAction {
  position: absolute;
  inset: 0;
}

.title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
}

.listing {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    text-decoration: none;
    color: #343434;
  }

  img:hover {
    opacity: 0.7;
  }
}

.favorite-category-item a {
  text-decoration: none;
  text-align: center;
  color: #343434;
  img:hover {
    opacity: 0.7;
  }
}

.link1 {
  font-family: var(--font-playfair);
  font-style: italic;
  font-size: 0.95rem;

  &:hover {
    color: black;
    font-weight: bolder;
  }
}

.link2 {
  font-family: var(--font-playfair);
  font-style: italic;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  white-space: wrap;
  @media (min-width: 767px) {
    font-size: 1.5rem;
  }
}

.link3 {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 0.125rem;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid black;
  }
}

.seoContent {
  max-width: 965px;
  margin: 0 auto 4rem;
  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 1.125rem;
  }
  p {
    font-size: 0.875rem;
  }
  a {
    color: black;
  }
}

.imageRepeater {
  a,
  p {
    color: black;
    text-decoration: none;
  }

  .link4 {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid transparent;

    &:after {
      display: block;
      content: '';
      border-bottom: 1px solid black;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }
  .link4 {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}
