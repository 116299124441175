/* Modal.module.scss */
.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.closeIcon {
  position: absolute;
  top: 1rem;
  right: 10px;
  cursor: pointer;
}
