.headline {
  background-color: #f6f5f1;
  padding: 0.312rem 0;
  text-align: center;
  font-style: italic;
  font-family: var(--font-playfair);
  font-weight: normal;
  line-height: 1.15;
}

.linkAction {
  position: absolute;
  inset: 0;
}