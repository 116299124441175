.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 62.5rem;
  margin: 0 auto;
}

.card {
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  &:hover {
    opacity: 0.7;
  }
}

@media (max-width: 768px) {
  .card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.buttonContainer {
  transform: translateX(-50%);
  position: absolute;
  bottom: 15%;
  left: 50%;
}

.button {
  background-color: #000000;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  width: 11.375rem;
  height: 2.563rem;
  text-transform: uppercase;
}
