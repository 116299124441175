.aboutContent {
  color: #000;
  margin-bottom: 1.5625rem;
  font-size: 1.063rem;

  @media (max-width: 1200px) {
    margin-top: 2rem;
    font-size: 1rem;
  }

  p {
    line-height: 1.15;
    margin-bottom: 0.915rem;
    @media (max-width: 768px) {
      letter-spacing: normal;
      line-height: 1.25rem;
      text-align: left !important;
    }
  }

  @media (min-width: 1200px) {
    h1:first-of-type {
      padding-bottom: 10px;
    }

    p:has(strong) {
      margin: 0px !important;
    }
  }

  h2 {
    color: #343434;
    letter-spacing: 0.025rem;
    margin-top: 2.3125rem;
    margin-bottom: 1rem;
    font-family: var(--font-alegreya);

    @media (max-width: 1200px) {
      text-align: left !important;
      font-size: 18px !important;
    }

    @media (min-width: 1200px) {
      font-size: 24px !important;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  h3,
  h4,
  h5 {
    color: #343434;
    letter-spacing: 0.025rem;
    font-weight: bold;
    @media (max-width: 1200px) {
      text-align: left !important;
      font-size: 16px !important;
    }

    @media (min-width: 1200px) {
      font-size: 17px !important;
    }
  }

  a {
    text-decoration: underline;
    color: #000;
    word-break: break-all;
  }

  ol {
    font-weight: bold;
    padding-left: 1rem;
    @media (max-width: 768px) {
      padding-left: 0.75rem;
    }
  }
}

.headline {
  font-weight: bold;
  color: #343434;
  letter-spacing: 0.044375rem;
  font-family: var(--font-playfair);

  @media (max-width: 1200px) {
    font-size: 18px !important;
  }

  @media (min-width: 1200px) {
    font-size: 26px !important;
  }
  padding-bottom: 12px;
}
