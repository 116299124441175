//Fullscreen Mode
.fullscreenWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
}

// Mobile multiple images slider
.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0;
}

.container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.imageWrapper {
  flex: 0 0 30%;
  width: 100%;
  padding: 0.25rem;
  box-sizing: border-box;
}


.swipeList {
  display: block;
  width: 100%;
  //margin: 15px 0 5px 0;
  text-align: center;
}

.swipeItem {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 4px;
  border-radius: 50%;
  background: #eee;

  &.hidden {
    background: transparent;
  }

  &.active {
    background: #000;
  }
}

.discountLabel {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  background-color: black;
  color: white;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 1;
  @media (min-width: 767px) {
    left: 1.5rem !important;
  }
}

.soldOut {
  opacity: 0.4;
}

.wishlistHeart {
  position: absolute;
  cursor: pointer;
  top: 0.25rem;
  right: 0.5rem;
  z-index: 1;
}